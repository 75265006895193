import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  display: flex;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
  margin: 2rem auto;
  max-width: 990px;
  background-color: #fff;

  img {
    max-width: 200px;
    height: auto;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }
`;

const TextContainer = styled.div`
  font-size: 1.125rem;
  line-height: 1.2;
  padding: 0 1rem;

  @media (min-width: 1024px) {
    padding: 0;
    font-size: 1.375rem;
  }

  p {
    margin-bottom: 1rem;
  }
`;

const LeftTextRightImage = ({ blok }) => {
  return (
    <Container>
      <TextContainer>{render(blok.text)}</TextContainer>
      <div>
        <img src={blok.image.filename} alt={blok.alt} />
      </div>
    </Container>

  );
};

export default LeftTextRightImage;
