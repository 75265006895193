import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  width: 100%;
  padding-top: 4.5rem;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;

  img {
    width: 100%;
    max-width: 626px;
  }
`;

const FirstDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1080px;
  margin: auto;
  width: 100%;
  position: relative;
  padding: 0 15px;

  img {
    position: absolute;
    max-width: 220px;
    width: 220px;
    height: auto;
    right: 0;
    top: -200px;
  }

  h2 {
    font-size: 32px;
    line-height: 1;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    color: #222;
    margin-bottom: 1rem;
    line-height: 1.45;
  }

  @media (min-width: 768px) {
    img {
      max-width: 575px;
      width: 575px;
      right: -100px;
    }

    h2 {
      font-size: 56px;
    }

    p {
      font-size: 1.125rem;
    }
  }

  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const FirstDescription = styled.div`
  @media (min-width: 768px) {
    max-width: 50%;
  }
`;

const SecondDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  margin: auto;
  width: 100%;
  padding: 0 15px;
  margin-top: 20px;

  img {
    width: 135px;
    margin-bottom: 15px;
    height: auto;
  }

  p {
    font-size: 1rem;
    color: #222;
    margin-bottom: 1rem;
    line-height: 1.45;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    img {
      margin-right: 40px;
      height: fit-content;
    }

    p {
      font-size: 1.125rem;
    }
  }

  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const JobsFirstBlok = ({ blok }) => {
  const firstDescription = render(blok.description);
  const secondDescription = render(blok.second_description);
  return (
    <Container>
      <FirstDescriptionContainer>
        <FirstDescription>{firstDescription}</FirstDescription>
        <img src={blok.image.filename} alt={blok.image.alt} />
      </FirstDescriptionContainer>
      <SecondDescriptionContainer>
        <img src={blok.second_image.filename} alt={blok.second_image.alt} />
        <div>{secondDescription}</div>
      </SecondDescriptionContainer>
    </Container>
  );
};

export default JobsFirstBlok;
