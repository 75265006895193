import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  height: 100%;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BackgorundContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: row;
  position: fixed;
  background-color: #f4f4f4;
  position: absolute;

  img {
    display: none;
    height: 22rem;
    width: 100%;
    background-color: #fff;
  }

  @media (min-width: 768px) {
    img {
      display: block;
    }
  }
`;

const BodyContainer = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1060px;
  margin: 20px auto;

  h1 {
    background: #ffee00;
    color: #000;
    padding: 0.5rem; 1rem;
    width: fit-content;
    font-size: 0.875rem;
    line-height: 1.3;
    margin-bottom: 1rem;
  }
`;

const AssetContainer = styled.div`
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 3px;
  padding: 2rem;

  p {
    margin-bottom: 2rem;
  }
`;

const AssetGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  img {
    max-width: 190px;
    align-self: center;
    margin: 0.5rem;
    
    &:hover {
      cursor: pointer;
    }
  }
`;

const AssetPage = ({ blok }) => {
  return (
    <>
      <Container>
        <BackgorundContainer>
          <img src={blok.background_image.filename} alt={blok.background_image.alt} />
        </BackgorundContainer>
        <BodyContainer>
          <h1>{blok.title}</h1>
          <AssetContainer>
            <p>{blok.description}</p>
            <AssetGrid>
              {blok.assets.map((asset, index) => (
                <img src={asset.filename} alt={asset.alt} key={index} onClick={() => window.open(asset.filename, '_blank')} />
              ))}
            </AssetGrid>
          </AssetContainer>
        </BodyContainer>
      </Container>
    </>
  );
};


export default AssetPage;
