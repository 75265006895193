import * as React from 'react';
const ArrowRight = (props) => (
  <svg
    aria-hidden="true"
    clipRule="evenodd"
    fillRule="evenodd"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m1.5 2 22 22-22 22"
      fill="none"
      stroke="#0f65ef"
      strokeWidth={4}
      transform="translate(12.5 1)"
    />
  </svg>
);
export default ArrowRight;