import styled from 'styled-components';

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-image: url(${props => props.image.filename});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  height: 22rem;
  padding: 0 15px;

  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 990px;
  width: 100%;

  h1{
    background-color: #ffee00;
    font-size: 0.875rem;
    margin-bottom: 1.5rem !important;
    padding: 0.5rem 1rem;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }
  
  p {
    background-color: #ffee00;
    line-height: 1.45;
    max-width: 650px;
    font-size: 1.75rem;
    text-align: left;
    font-weight: 800;
    padding: 0.5rem 1rem;
  }

  @media (min-width: 768px) {
    margin: 0px auto;
  }
`;

const BannerImageWIthText = ({ blok }) => {
  return (
    <Banner image={blok.image} aria-label={blok.image.alt}>
      <BannerContent>
        <h1 id={blok.id}><b>{blok.title}</b></h1>
        <p>{blok.description}</p>
      </BannerContent>
    </Banner>

  );
};

export default BannerImageWIthText;
