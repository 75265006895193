import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { styled } from 'styled-components';

const getColumnWidth = (columns, column_gap) => {
  const columnGapWidth = column_gap * (columns - 1) / columns;
  return `100% / ${columns} - ${columnGapWidth}px`;
}

// formula used for the grid-template-columns property:
// repeat(auto-fit, minmax(max(min_item_width, column_width), 1fr))
// where column_width = 100% / max_columns - column_gap * (max_columns - 1) / max_columns
// and min_item_width is the minimum width of the item in pixels
const GridContainer  = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(auto-fit, minmax(max(${props.min_item_width || 0}px, ${getColumnWidth(props.max_columns || 1, props.column_gap)}), 1fr))`};
  column-gap: ${(props) => props.column_gap || 0}px;
  row-gap: ${(props) => props.row_gap || 0}px;
`

const Grid2D = ({ blok }) => {
  return (
    <GridContainer {...blok} {...storyblokEditable(blok)} key={blok._uid}>
      {blok.items.map((blok) => (
        <div key={blok._uid} >
          <StoryblokComponent blok={blok} />
        </div>
      ))}
    </GridContainer>
  );
};

export default Grid2D;
