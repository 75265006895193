import styled from 'styled-components';
import InThePress from './InThePress';
import Communications from './Communications';
import BrandAssets from './BrandAssets';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1060px;
  margin: auto;
  padding: 0 15px;

  h1 {
    display: none;
  }

  @media (min-width: 768px) {
    paddding: 0;

    h1 {
      width: fit-content;
      display: block;
      background: #ffee00;
      color: #000;
      padding: 0.5rem; 1rem;
      margin: 3rem 0 1rem 0;
      font-size: 0.875rem;
      line-height: 1.3;
    }
  }
`;

const SecondRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    & > div:first-child {
      width: 70%;
    }

    & > div:last-child {
      width: 28%;
    }
  }
`;

const FirstColumn = styled.div`

`;

const PressPage = ({ blok }) => {
  return (
    <Container>
      <h1>{blok.title}</h1>
      <SecondRow>
        <FirstColumn>
          <InThePress blok={blok.press[0]} />
          <BrandAssets blok={blok.brand_assets[0]} />
        </FirstColumn>
        <Communications blok={blok.communications[0]} />
      </SecondRow>
    </Container>
  );
};

export default PressPage;
