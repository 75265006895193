import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  width: 100%;
  padding: 5rem 15px;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  width: 100%;

  @media (min-width: 1040px) {
    padding: 5rem 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  max-width: 1040px;
  width: 100%;
  margin: auto;
  margin-bottom: -90px;

  h3 {
    font-size: 32px;
    line-height: 1;
  }

  img {
    position: relative;
    width: 200px;
    right: 50px;
    top: -200px;
  }

  @media (min-width: 768px) {
    margin-bottom: -175px;

    img {
      width: 350px;
      top: -200px;
    }

    h3 {
      font-size: 56px;
    }
  }
`;

const CardContainer = styled.div`
  margin-bottom: 1rem;
  z-index: 99999;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    min-height: 100px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 3px;
    font-size: 1rem;
    color: #222;
    padding: 1.5rem;
    margin-bottom: 1rem;

    a {
      text-decoration: none;
      color: #0f65ef;
      font-weight: 800;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    max-width: 1040px;
    width: 100%;
    margin: auto;
    justify-content: space-between;

    div {
      width: 49%;
      line-height: 1.45;
      font-size: 1.125rem;
      min-height: 180px;
    }
  }
`;

const WorkplaceAwards = ({ blok }) => {
  const firstAward = render(blok.first_award);
  const secondAward = render(blok.second_award);
  return (
    <Container>
      <TitleContainer>
        <h3>{blok.title}</h3>
        <img src={blok.image.filename} alt={blok.image.alt} />
      </TitleContainer>
      <CardContainer>
        <div>{firstAward}</div>
        <div>{secondAward}</div>
      </CardContainer>
    </Container>
  );
};

export default WorkplaceAwards;
