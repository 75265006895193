import React, { useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { render } from 'storyblok-rich-text-react-renderer';
import { apiPrefix, recaptcha_site_key } from '../../../constants';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  h2 {
    &:hover {
      color: #093c8f;
      transition: color .15s ease 0s;
    }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 2rem;
  max-width: 480px;
  width: 100%;
  animation: ${fadeIn} 0.2s ease-out;
  margin: 100px 15px 0 15px;
  position: relative;
  max-height: 80%;
  overflow-y: auto;

  p {
    color: #222;
    font-size: 1.375rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  #close-button {
    position: absolute;
    top: 25px;
    right: 25px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
`;

const Input = styled.div`
  margin-bottom: 15px;

  label {
    font-weight: 600;
    line-height: 1.5;
    color: #222;
  }

  input {
    margin: 0.5rem 0;
    background: #fff;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    width: 100%;
  }
`;

const TextArea = styled.div`
  margin-bottom: 15px;

  label {
    font-weight: 600;
    line-height: 1.5;
    color: #222;
    margin-top: 1rem;
  }

  textarea {
    font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
    width: 100%;
    max-width: 100%;
    margin-top: 0.5rem;
    min-height: 6rem;
    padding: 0.5rem 0.75rem;
    background: #fff;
    font-size: 1rem;
    line-height: 1.5rem;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
  }
`;

const Radio = styled.div`
  margin-bottom: 15px;

  label {
    font-weight: 600;
    line-height: 1.5;
    color: #222;
    margin-top: 1rem;
  }
`;

const RadioInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    font-weight: 400;
    font-size: 0.875rem;
    cursor: pointer;
    line-height: 1.5rem;
    padding-left: 15px;
    margin: 0;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    font-weight: 400;
    font-size: 0.875rem;
    cursor: pointer;
    line-height: 1.5rem;
    padding-left: 15px;
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-top: 2rem;

  #cancel-button {
    border: 1px solid #0f65ef;
    color: #0f65ef;
    background: none;
    padding: 0.5rem 0.875rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color .1s ease 0s;

    &:hover {
      background-color: #0f65ef;
      color: #fff;
    }
  }

  #submit-button {
    padding: 0.5rem 0.875rem;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    border: 1px solid transparent;
    text-align: center;
    background-color: #0f65ef;
    color: #fff;
    border-color: #0f65ef;
    transition: background-color .1s ease 0s;
    margin-left: 1rem;

    &:hover {
      background-color: #093c8f;
      transition: background-color .15s ease 0s;
    }
  }
`;

const Checkbox = styled.div`
  display: inline;
  flex-direction: row;
`;

const Message = styled.div`
  background-color: ${props => props.$type === 'success' ? '#e1efd0' : '#FDECE9'};
  color: ${props => props.$type === 'success' ? '#68af15' : '#EE3322'};
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  p {
    color: ${props => props.$type === 'success' ? '#68af15' : '#EE3322'} !important;
    font-size: 1rem;
    font-weight: 600;
  }
`;

const FormLink = ({blok}) => {
  const forms = blok.form;
  const [showModal, setShowModal] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const recaptchaRef = useRef(null);

  if (!blok.title) return null;

  const toggleModal = () => {
    setShowModal(!showModal);
    document.body.style.overflow = showModal ? 'auto' : 'hidden';
  };

  const handleOptionChange = (event, groupName) => {
    const optionValue = event.target.value;

    if (optionValue === 'credit correction') {
      setSelectedOptions([]);
    }

    setSelectedOptions(prevState => ({
      ...prevState,
      [groupName]: optionValue,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitError('');

    const token = await recaptchaRef.current.executeAsync();

    const rawFormData = new FormData(event.target);
    rawFormData.delete('g-recaptcha-response');
    const formData = Object.fromEntries(rawFormData);
    const data = {};
    // add display names
    (Object.keys(formData) || []).forEach((name) => {
      let displayField;
      blok.form?.forEach((section) => {
        const value = section?.fields?.find((field) => {
          return field?.name === name;
        });
        if (value) {
          displayField = value;
        }
      });
      data[name] = {
        name,
        label: displayField?.label || '',
        value: formData[name]
      }
    });

    const body = {
      type: blok.type,
      title: blok.title,
      data,
      token,
    };

    fetch(`${apiPrefix}feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => {
        if (!response.ok) {
          setSubmitSuccess(false);
          throw new Error('Network response was not ok');
        }
        setSubmitSuccess(true);
        return response.json();
      })
      .then(data => {
        setSubmitSuccess(true);
        console.log(data);
      })
      .catch(error => {
        setSubmitSuccess(false);
        setSubmitError('There was an error submitting this request');
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  return (
    <Container>
      <LinkContainer onClick={toggleModal}>
        <h2>{blok.title}</h2>
        <div>{render(blok.content)}</div>
      </LinkContainer>
      {showModal && (
        <ModalOverlay>
          <ModalContent>
              <form onSubmit={handleSubmit}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={recaptcha_site_key}
                />
                {!submitSuccess && (
                  <>
                    <p>{blok.title}</p>
                    {forms.map((form, index) => {
                      if (!Object.values(selectedOptions).includes(form.name) && form.name) {
                        return <></>;
                      }

                      return (
                        <div key={index}>
                          {form.fields.map((field, formIndex) => {
                            if (field.component === 'form_textarea') {
                              return (
                                <TextArea key={formIndex}>
                                  <label htmlFor={field.name}>{field.label}</label>
                                  <textarea id={field.name} name={field.name} placeholder={field.placeholder} required={field.required} />
                                </TextArea>
                              );
                            } else if (field.component === 'form_radio') {
                              return (
                                <Radio key={formIndex}>
                                  <label htmlFor={field.name}>{field.label}</label>
                                  {field.options.map((option, radioIndex) => {
                                    return (
                                      <RadioInput key={radioIndex}>
                                        <input
                                          type="radio"
                                          id={option.name}
                                          name={field.name}
                                          value={option.value}
                                          checked={selectedOptions[field.label] === option.value}
                                          onChange={event => handleOptionChange(event, field.label)}
                                          required={field.required}
                                        />
                                        <label htmlFor={option.name}>{option.name}</label>
                                      </RadioInput>
                                    )})}
                                </Radio>
                              );
                            } else if (field.component === 'form_input') {
                              return (
                                <Input key={formIndex}>
                                  <label htmlFor={field.name}>{field.label}</label>
                                  <input type={field.type} id={field.name} name={field.name} placeholder={field.placeholder} required={field.required} />
                                </Input>
                              );
                            } else if (field.component === 'form_label') {
                              return (
                                <Input key={formIndex}>
                                  <p htmlFor={field.name}>{field.label}</p>
                                </Input>
                              );
                            } else if (field.component === 'form_checkbox_group') {
                              return (
                                <CheckboxGroup key={formIndex}>
                                  <label htmlFor={field.label}>{field.label}</label>
                                  {field.options.map((option, checkboxIndex) => {
                                    return (
                                      <Checkbox key={checkboxIndex}>
                                        <input
                                          type="checkbox"
                                          id={option.name}
                                          name={field.name}
                                          value={option.value}
                                        />
                                        <label htmlFor={option.name}>{option.text}</label>
                                      </Checkbox>
                                    )})}
                                </CheckboxGroup>
                              );
                            } else {
                              return <></>;
                            }
                          })}
                        </div>
                      )
                    })}

                    {submitError && <Message $type="error">{submitError}</Message>}
                    <ButtonContainer>
                      <button id="cancel-button" onClick={() => setShowModal(false)} type="button">Cancel</button>
                      <button id="submit-button" type="submit">Send Feedback</button>
                    </ButtonContainer>
                  </>
                )}

                {submitSuccess && ( 
                  <Message $type="success">
                    <p>Your submission has been received.</p>
                  </Message>
                )}

                <button onClick={toggleModal} id="close-button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </button>
              </form>
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default FormLink;
