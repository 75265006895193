import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';
import FormLink from './FormLink';
import ContactRte from './ContactRte';

const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: #f4f4f4;
  height: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1060px;
  margin: auto;
  padding: 0 15px;
  height: 100vh;
  z-index: 99;

  h1 {
    display: none;
  }

  @media (min-width: 768px) {
    paddding: 0;

    h1 {
      width: fit-content;
      display: block;
      background: #ffee00;
      color: #000;
      padding: 0.5rem; 1rem;
      margin: 3rem 0 1rem 0;
      font-size: 0.875rem;
      line-height: 1.3;
    }
  }
`;

const BackgroundImage = styled.div`
  width: 100%;
  display: none;
  height: 100vh;
  flex-direction: row;
  position: absolute;
  background-color: #f4f4f4;
  background-image: ${props => `url(${props.imageUrl})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 22rem;

  @media (min-width: 768px) {
    display: flex;
    background-size: cover;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;


  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const MapContent = styled.div`
  width: 100%;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 3px;
  height: fit-content;
  align-self: center;
  background-color: #fff;
  margin: 1rem 0;

  img {
    width: 100%;
  }

  @media (min-width: 768px) {
    max-width: 320px;
    margin-left: 30px;
  }
`;

const AddressContainer = styled.div`
  padding: 15px;
  color: #222;

  h2 {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.3;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const FormsContainer = styled.div`
  width: 100%;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 3px;
  padding: 3rem;
  margin-top: 1rem;

  h2 {
    color: #0f65ef;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.3;
    text-decoration: none;

    a {
      cursor: pointer;
      color: #0f65ef;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.3;
      text-decoration: none;

      &:hover {
        color: #093c8f;
        transition: color .15s ease 0s;
      }
    }
  }

  p {
    margin-bottom: 2rem;
    color: #222;
    font-size: 1rem;
    line-height: 1.5;

    a {
      text-decoration: none;
      color: #222;
    }
  }

  > div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
  }

  @media (min-width: 768px) {
    > div {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const ContactPage = ({ blok }) => {
  const addressText = render(blok.address);

  return (
    <Container>
      <BackgroundImage imageUrl={blok.background_image.filename} />
      <ContentContainer>
        <h1>{blok.title}</h1>
        <Content>
          <FormsContainer>
            <div>
              {blok.forms.map((item, index) => {
                if (item.form) {
                  return <FormLink key={index} blok={item} />
                } else {
                  return <ContactRte key={index} blok={item} />
                }
              })}
            </div>
          </FormsContainer>
          <MapContent>
            <img src={blok.map_image.filename} alt={blok.map_image.alt} />
            <AddressContainer>{addressText}</AddressContainer>
          </MapContent>
        </Content>
      </ContentContainer>
    </Container>
  );
};


export default ContactPage;
