import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';
import PerkCard from './PerkCard';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0 15px 4.5rem 15px;

  h3 {
    font-size: 32px;
    line-height: 1;
    margin-bottom: 1rem;
  }

  @media (min-width: 1024px) {
    padding: 0 0 4.5rem 0;
    max-width: 1060px;
    margin: auto;
  }
`;

const DescriptionContainer = styled.div`
  p {
    margin-bottom: 2rem;
    line-height: 1.45;
    font-size: 1rem;
    color: #222;
  }
`;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
`;

const JobsPerks = ({ blok }) => {
  const desc = render(blok.description);

  return (
    <Container>
      <h3>{blok.title}</h3>
      <DescriptionContainer>{desc}</DescriptionContainer>
      <CardsContainer>
        {blok.perks_cards.map((perk) => (
          <PerkCard blok={perk} key={perk._uid} />
        ))}
      </CardsContainer>
    </Container>
  );
};

export default JobsPerks;
