export const SiteMetaData = {
  buzzfeed: {
    siteName: 'BuzzFeed',
    copyright: 'Copyright BuzzFeed, Inc. All rights reserved.',
    socialAccounts: {
      facebook: 'buzzfeed',
      twitter: 'buzzfeed',
      instagram: 'buzzfeed',
      youtube: 'buzzfeed',
    },
    facebookAppID: 45075597673,
    twitterSiteId: 5695632,
  },
  tasty: {
    siteName: 'Tasty',
    copyright: 'Copyright BuzzFeed, Inc. All rights reserved.',
    socialAccounts: {
      facebook: 'buzzfeedtasty',
      twitter: 'tasty',
      instagram: 'buzzfeedtasty',
      youtube: 'buzzfeedtasty',
    },
    facebookAppID: 1060852764021442,
    twitterSiteId: 1614251518827491,
  }
}

export const StaticMetaData = {
  buzzfeed: {
    about: {
      title: 'About BuzzFeed',
      description: 'BuzzFeed, Inc. is a premier digital media company for the most diverse, most online, and most socially engaged generation the world has ever seen.',
    },
  },
  tasty: {}, // needs to exist
  huffpost: {}, // needs to exist
};
