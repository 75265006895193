import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { styled, ThemeProvider } from 'styled-components';

import { trackClientExternalLink } from '../../client-event-tracking';

import PageText from './Components/PageText';
import Phone from './Components/Phone';
import BackgroundBlobs from './Components/BackgroundBlobs';

import siteConfig from './siteConfig';
import themes from './themes';


const PageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`
const ContentContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: left;
  align-items: center;
  margin: 0;
  gap: 2rem;
  overflow: hidden;

  @media (min-width: 48rem) {
    width: 48rem;
    margin: 0 auto;
    padding: 4rem 1rem;
    justify-content: center;
    overflow: visible;
  }
`


export default function AppPromoPage({blok, site}) {

  const [platform, setPlatform] = useState(null);
  const router = useRouter();

  const campaignId = router.query.ct || blok.campaignId || 'default';
  const doRedirect = !router.query.noredirect;

  const { domain, appStoreLinks, showBackgroundBlobs } = siteConfig[site];

  const trackStoreClick = url => {
    trackClientExternalLink({}, {
      context_page_id: 'app_promo',
      context_page_type: 'utility',
      destination: site,
      page_edition: 'en-us',
      target_content_url: url,
      item_name: 'open_appstore',
      item_type: 'button',
      unit_name: 'app_page',
      unit_type: 'body',
    });
  }

  useEffect(() => {
    // determine platform
    const userAgent = window.navigator.userAgent;
    const _platform =
      /iP(hone|ad|od)/i.test(userAgent) ? 'ios' :
        /Android/i.test(userAgent) ? 'android' :
          'desktop';
    setPlatform(_platform);

    // if we're in a mobile browser then we'll redirect the user automatically
    if (_platform != 'desktop' && doRedirect) {
      const redirectUrl = appStoreLinks[_platform] + campaignId;
      trackStoreClick(redirectUrl, _platform);
      window.location.assign(redirectUrl);
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let path = router.asPath.replace('/static-pages/'+site+'/', '');
  path = path.split('?')?.[0];  // remove any query strings
  const qrLink = domain + path + '?ct=' + campaignId;
  const storeLinks = {
    ios: appStoreLinks.ios + campaignId,
    android: appStoreLinks.android + campaignId,
  }

  return (
    <ThemeProvider theme={themes[site]}>
      <PageContainer>
        <ContentContainer>
          <PageText
            platform={platform}
            blok={blok}
            storeLinks={storeLinks}
            qrLink={qrLink}
            trackStoreClick={trackStoreClick}
          />
          <Phone
            screenshots={blok.screenshots}
            darkMode={blok.screenshotDarkMode}
          />
        </ContentContainer>
        {showBackgroundBlobs && <BackgroundBlobs />}
      </PageContainer>
    </ThemeProvider>
  );
}
