// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { sentryDsn as dsn } from './constants';

Sentry.init({
  dsn,
  tracesSampleRate: 0,
  integrations(integrations) {
    return integrations.filter(
      integration => integration.name !== 'Breadcrumbs'
    );
  },
  environment: process.env.NODE_ENV,
  sampleRate: 0.01,
  attachStacktrace: true,
});
