import { styled } from 'styled-components';

import QRCode from 'react-qr-code';

const PageTextContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 1rem;
  font-size: 1.1rem;
  text-wrap: pretty;

  @media (min-width: 48rem) {
    width: 55%;
    padding: 0;
    margin-top: -2rem;
    font-size: 1.2rem;
  }

  h1 {
    margin: 0 0 0.5rem;
    font-family: ${({theme}) => theme.heading.fontFamily};
    font-size: ${({theme}) => theme.heading.fontSize}rem;
    font-weight: 800;
    line-height: 1.1;

    @media (min-width: 48rem) {
      font-size: ${({theme}) => (theme.heading.fontSizeDesktop)}rem;
    }
  }

  p {
    margin: 0 0 0.2rem;
    font-weight: 400;
  }

  b {
    font-weight: 800;
  }

  p > span {
    display: inline-block;
    margin-right: 0.5rem;
    padding: 1px 4px;
    font-size: 1rem;
    font-weight: 800;
    border-radius: 2px;
    background: #ff0;
  }
`

const AppStores = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
`

const QRCodeContainer = styled.div`
  display: none;
  @media (min-width: 48rem) {
    display: flex;
  }

  p {
    width: 10rem;
    margin-left: 1rem;
  }

  img {
    width: 116px;
    height: 116px;
  }
`

const StoreBadges = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    display: block;
  }

  img {
    width: 10rem;
  }

  @media (min-width: 48rem) {
    flex-direction: row;
    gap: 2rem;
    margin-top: 1.5rem;
  }
`

export default function PageText({ platform, blok, storeLinks, qrLink, trackStoreClick }) {
  return (
    <PageTextContainer>
      <h1>{blok.title}</h1>
      {blok.featureText && (
        <p>
          {blok.newLabel && <span>NEW!</span>}
          <b>{blok.featureText}</b>
        </p>
      )}
      <p>
        {blok.newLabel && !blok.featureText && <span>NEW!</span>}
        {blok.description}
      </p>
      {/* Todo: maybe add these in future
        {blok.bullets && blok.bullets.length > 0 && (
          <ul>
            {blok.bullets.map((text, index) => <li key={index}>{text}</li>)}
          </ul>
        )} */}
      <AppStores>
        <QRCodeContainer>
          <QRCode
            size={120}
            style={{ height: '120px', width: '120px' }}
            value={qrLink}
            viewBox={'0 0 120 120'}
            bgColor={'rgba(255,255,255,0)'}
          />
          <p>Scan this code with your phone to download</p>
        </QRCodeContainer>
        <StoreBadges>
          {platform && (platform === 'ios' || platform === 'desktop') && (
            <a href={storeLinks.ios} onClick={() => trackStoreClick(storeLinks.ios)}>
              <img src="https://img.buzzfeed.com/store-an-image-prod-us-east-1/W6FNAM_AK.png?output-format=png" alt="App Store" />
            </a>
          )}
          {platform && (platform === 'android' || platform === 'desktop') && (
            <a href={storeLinks.android} onClick={() => trackStoreClick(storeLinks.android)}>
              <img src='https://img.buzzfeed.com/store-an-image-prod-us-east-1/zvcP0BAhI.png?output-format=png' alt="Play Store" />
            </a>
          )}
        </StoreBadges>
      </AppStores>
    </PageTextContainer>
  );
}
