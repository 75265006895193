const themes = {
  buzzfeed: {
    blobColors: ['#ec63ac', '#ffe342', '#6bd5ff'],
    heading: {
      fontFamily: 'Proxima Nova',
      fontSize: 2.4,
      fontSizeDesktop: 3.6,
    }
  },
  huffpost: {
    blobColors: [],
    heading: {
      fontFamily: 'Proxima Nova Condensed',
      fontSize: 3,
      fontSizeDesktop: 4,
    }
  },
  tasty: {
    blobColors: ['#79dcf1', '#f9e932', '#e40754'],
    heading: {
      fontFamily: 'Proxima Nova',
      fontSize: 2.8,
      fontSizeDesktop: 4.2,
    }
  },
}

export default themes;
