import React from 'react';
import Head from 'next/head';

const SEO = ({ blok }) => {
  const {
    title,
    description,
    image,
    og_image,
    og_title,
    og_description,
    twitter_image,
    twitter_title,
    twitter_description,
 } = blok;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description || og_description || twitter_description} />
      <link key="image_src" rel="image_src" href={image || og_image || twitter_image} />
      <meta property="og:title" content={og_title || title} />
      <meta property="og:description" content={og_description || description} />
      <meta property="og:image" content={og_image || image} />
      <meta name="twitter:title" content={twitter_title || og_title || title} />
      <meta name="twitter:description" content={twitter_description || og_description || description} />
      <meta name="twitter:image" content={twitter_image || og_image || image} />
    </Head>
  );
};

export default SEO;
