import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: row;
  position: fixed;
  z-index: -1;
  background-color: #f4f4f4;
  margin-top: -60px;

  img {
    display: none;
    height: 22rem;
    width: 100%;
    background-color: #fff;
  }

  @media (min-width: 768px) {
    img {
      display: block;
    }
  }
`;

const PressBackground = ({ blok }) => {
  return (
    <Container>
      <img src={blok.image.filename} alt={blok.image.alt} />
    </Container>
  );
};

export default PressBackground;
