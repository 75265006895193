import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  height: 100%;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PageContainer = styled.div`
  width: 100%;
  max-width: 1060px;
  margin: 0 auto;
  padding: 2rem 15px 0 15px;

  h1 {
    background: #ffee00;
    color: #000;
    padding: 0.5rem; 1rem;
    width: fit-content;
    font-size: 0.875rem;
    line-height: 1.3;
    margin-top: 1rem;
  }

  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const ContentContainer = styled.div`
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 2rem;
  margin-top: 1rem;
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;

  h2, h3 {
    color: #0f65ef;
    font-size: inherit;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  b {
    font-weight: 600;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: #0f65ef;
  }

  ul {
    padding-bottom: 1rem;
    padding-left: 2rem;
  }

  th {
    text-align: left;
  }

  td {
    padding: 10px;
  }

  table {
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`;

const AccessibilityPage = ({ blok }) => {
  const content = render(blok.content);

  return (
    <Container>
      <PageContainer>
        <h1>{blok.title}</h1>
        <BodyContainer>
          <SectionContainer>
            <ContentContainer>
              {content}
            </ContentContainer>
          </SectionContainer>
        </BodyContainer>
      </PageContainer>
    </Container>
  );
};


export default AccessibilityPage;
