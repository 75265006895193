const siteConfig = {
  buzzfeed: {
    domain: 'https://www.buzzfeed.com/',
    appStoreLinks: {
      ios: 'https://apps.apple.com/us/app/buzzfeed-quiz-trivia-news/id352969997?mt=8&pt=329394&ct=',
      android: 'https://play.google.com/store/apps/details?id=com.buzzfeed.android&utm_source=buzzfeed&utm_medium=web&utm_campaign=',
    },
    showBackgroundBlobs: true,
  },
  huffpost: {
    domain: 'https://www.huffpost.com/',
    appStoreLinks: {
      ios: 'https://apps.apple.com/us/app/huffpost-news-politics/id306621789?mt=8&pt=216757&ct=',
      android: 'https://play.google.com/store/apps/details?id=com.huffingtonpost.android&utm_source=huffpost&utm_medium=web&utm_campaign=',
    },
    showBackgroundBlobs: false,
  },
  tasty: {
    domain: 'https://tasty.co/',
    appStoreLinks: {
      ios: 'https://apps.apple.com/us/app/tasty-recipes-cooking-videos/id1217456898?mt=8&pt=329394&ct=',
      android: 'https://play.google.com/store/apps/details?id=com.buzzfeed.tasty&utm_source=tasty&utm_medium=web&utm_campaign=',
    },
    showBackgroundBlobs: true,
  },
}

export default siteConfig;
