import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin-bottom: 25px;

  img {
    display: flex;
    max-width: 30%;
    height: auto;
    max-height: 100px;
    max-width: 100px;
    background-color: #fff;

    @media (min-width: 768px) {
      max-height: 153px;
      max-width: 153px;
    }
  }
`;

const InfoContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  h4 {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    line-height: 1.2;
  }

  p {
    font-size: 0.875rem !important;
    line-height: 1.3 !important;
  }

  a {
    text-decoration: none;
    color: #222;
  }
`;


const DescriptionContainer = styled.div`
  p {
    margin-bottom: 2rem;
    line-height: 1.45;
    font-size: 1rem;
    color: #222;
  }
`;

const PerkCard = ({ blok }) => {
  const desc = render(blok.description);

  return (
    <Container>
      <img src={blok.image.filename} alt={blok.image.alt} />
      <InfoContainer>
        <h4>{blok.title}</h4>
        <DescriptionContainer>{desc}</DescriptionContainer>
      </InfoContainer>
    </Container>
  );
};

export default PerkCard;
