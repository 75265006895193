import { styled, keyframes } from 'styled-components';

const transform = keyframes`
  0%, 100% { border-radius: 33% 67% 70% 30% / 30% 40% 70% 70%; }
  20% { border-radius: 37% 63% 51% 49% / 37% 35% 35% 63%; }
  40% { border-radius: 36% 64% 64% 36% / 64% 48% 52% 26%; }
  60% { border-radius: 37% 63% 51% 49% / 30% 30% 70% 73%; }
  80% { border-radius: 40% 60% 42% 58% / 51% 51% 49% 59%; }
`
const move_one = keyframes`
  0%, 500% { transform: none; }
  50% { transform: translate(5%, 5%) rotateY(10deg) scale(1.2); }
`
const move_two = keyframes`
  0%, 500% { transform: none; }
  50% { transform: translate(15%, 15%) rotate(-200deg) scale(1.3); }
`
const move_three = keyframes`
  0%, 500% { transform: none; }
  50% { transform: translate(25%, 20%) rotate(-200deg) scale(1.2); }
`

const rotate = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
`

const BlobContainer = styled.div`
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  top: 0;
  overflow: hidden;
  z-index: -1;
`
const Blobs = styled.div`
  position:relative;
  width: 90%;
  height: 650px;
  scale: 0.7;
  filter: blur(30px);
  animation: ${rotate} 16s linear infinite;

  @media (min-width: 48rem) {
    scale: 1;
    height: 750px;
  }
`
const Blob = styled.div`
  position: absolute;
  height: 400px;
  width: 450px;
  border-radius: 40% 50% 30% 40%;
  opacity: .3;
`
const BlobOne = styled(Blob)`
  background: ${({theme})=> theme.blobColors[0]};
  left: calc(50% - 400px);
  top: 100px;
  transform: rotate(-180deg);
  animation:
    ${transform} 6s ease-in-out infinite both alternate,
    ${move_one} 14s ease-in-out infinite both;
`
const BlobTwo = styled(Blob)`
  background: ${({theme})=> theme.blobColors[1]};
  left: calc(50% - 100px);
  top: 120px;
  transform: rotate(-180deg);
  animation:
    ${transform} 8s ease-in-out infinite both alternate,
    ${move_two} 10s ease-in-out infinite both;
`
const BlobThree = styled(Blob)`
  background: ${({theme})=> theme.blobColors[2]};
  left: calc(50% - 200px);
  top: 300px;
  transform: rotate(-180deg);
  animation:
    ${transform} 7s ease-in-out infinite both alternate,
    ${move_three} 18s ease-in-out infinite both;
`

export default function BackgroundBlobs() {
  return (
    <BlobContainer>
      <Blobs>
        <BlobOne />
        <BlobTwo />
        <BlobThree />
      </Blobs>
    </BlobContainer>
  )
}