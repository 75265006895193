import { useState, useEffect } from 'react';
import { styled } from 'styled-components';

const iconsDarkMode = 'https://img.buzzfeed.com/buzzfeed-static/static/2023-10/3/19/asset/2f071ffe95f2/sub-buzz-2802-1696362926-1.png';
const iconsLightMode = 'https://img.buzzfeed.com/buzzfeed-static/static/2023-10/3/19/asset/d99581650fb6/sub-buzz-1509-1696362918-1.png';

const SLIDESHOW_DURATION_SECONDS = 4;

const PhoneContainer = styled.div`
  position: relative;
  width: 50%;
  right: 40px;
  scale: 0.8;
  user-select: none;

  @media (min-width: 48rem) {
    position: relative;
    width: 45%;
    right: 0;
    scale: 1;
  }
`
const PhoneFrame = styled.div`
  width: 308px;
  border: 4px solid #3b444b;
  border-radius: 50px;

  transform: perspective(600px) translateY(0px) rotate(4deg) rotateX(0deg);
  transition: 300ms ease-in-out;
  box-shadow: 4px 6px 18px 6px rgba(0, 0, 0, .1);

  &:hover {
    transform: perspective(600px) translateY(-5px) rotate(2deg) rotateX(5deg);
    box-shadow: 8px 16px 32px 6px rgba(0, 0, 0, .2);
  }
`
const PhoneScreen = styled.div`
  width: 300px;
  height: 635px;
  border: 5px solid black;
  background: white;
  border-radius: 47px;
  position: relative;
`
const Image = styled.div`
  position: absolute;
  width: 290px;
  height: 625px;
  top: 0px;
  left: 0px;
  border-radius: 43px;
  background: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: ${props => `url(${props.imageUrl})`};
  transition: opacity 1.5s ease-in-out;
  opacity: ${props => props.opacity};
`
const PhoneHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 38px;
  border-top-left-radius: 47px;
  border-top-right-radius: 47px;
`
const Time = styled.div`
  position: absolute;
  top: 12px;
  left: 40px;
  font-size: 15px;
  color: ${props => props.darkMode ? '#fff' : '#111'};
`
const Island = styled.div`
  position: absolute;
  left: 105px;
  width: 86px;
  height: 25px;
  margin: 7px auto 0;
  background: black;
  border-radius: 50px;
`
const Camera = styled.div`
  position: absolute;
  top: 15px;
  right: 110px;
  width: 10px;
  height: 10px;
  background: #222;
  color: white;
  box-shadow: 0 0 1px grey;
  border-radius: 50px;
  z-index: 99999;
`
const Icons = styled.div`
  position: absolute;
  top: 15px;
  right: 22px;
  width: 61px;
  height: 11px;
  background-image: url(${props => props.darkMode ? iconsDarkMode : iconsLightMode});
  background-size: 100% 100%;
`

export default function Phone({ screenshots, darkMode}) {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((currentImage + 1) % screenshots.length);
    }, SLIDESHOW_DURATION_SECONDS * 1000);
    return () => clearInterval(timer);
  }, [currentImage, screenshots.length]);

  return (
    <PhoneContainer>
      <PhoneFrame>
        <PhoneScreen>
          {screenshots.map((screenshot, index) => {
            const opacity = currentImage === index ? 1 : 0;
            return (
              <Image
                imageUrl={screenshot.filename}
                opacity={opacity}
                key={index}
                alt="Screenshot showing BuzzFeed app"
              />
            )
          })}
          <PhoneHeader>
            <Time darkMode={darkMode}>9:41</Time>
            <Island></Island>
            <Camera></Camera>
            <Icons darkMode={darkMode}></Icons>
          </PhoneHeader>
        </PhoneScreen>
      </PhoneFrame>
    </PhoneContainer>
  )
}