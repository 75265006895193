import { storyblokEditable } from '@storyblok/react';
import styled from 'styled-components';

const Component = styled.h1`
  font-size: ${(props) => props.theme.fontSizes[props.size || 'xl']};
`;

const Header = ({ blok }) => {

  return (
    <Component size={blok.size} {...storyblokEditable(blok)} key={blok._uid}>
      {blok.text}
    </Component>
  );
};

export default Header;