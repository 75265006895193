const Iframe = ({ blok }) => {
  return (
    <iframe
      src={blok?.iframe_url}
      width="100%"
      height="350"
      frameBorder="0"
      scrolling="no"
    />
  );
};

export default Iframe;
