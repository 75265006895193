import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { styled } from 'styled-components';

const GridContainer  = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Grid = ({blok}) => {
  const columns = blok.columns;
  return (
    <GridContainer {...blok} {...storyblokEditable(blok)} key={blok._uid}>
      {columns.map((blok) => (
        <div key={blok._uid} >
          <StoryblokComponent blok={blok} />
        </div>
      ))}
    </GridContainer>
  );
};

export default Grid;
