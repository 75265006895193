import { storyblokEditable } from '@storyblok/react';
import styled from 'styled-components';

const sizes = {
  xs: 1,
  sm: 2,
  md: 4,
  lg: 8,
  xl: 16,
};

const spaces = {
  xs: 5,
  sm: 10,
  md: 15,
  lg: 25,
  xl: 40,
};

const DividerHr = styled.hr`
  width: 100%;
  border-bottom: ${(props) => `solid ${sizes[props.size || 'md']}px ${props.color};`}
  margin-top: ${(props) => spaces[props.spacing] && `${spaces[props.spacing]}px`};
  margin-bottom: ${(props) => spaces[props.spacing] && `${spaces[props.spacing]}px`};
`;

const Divider = ({ blok }) => {

  return (
    <DividerHr {...blok} {...storyblokEditable(blok)} key={blok._uid} />
  );
};

export default Divider;