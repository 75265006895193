import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 3px;
  padding: 1rem;
  align-items: center;
  height: 400px;
  max-width: 330px;

  p {
    font-size: 0.875rem;
    line-height: 1.3;
  }
  
  .card-index {
    color: #0f65ef;
    margin-right: 0.5rem;
    font-size: 1.125rem !important;
    line-height: 1.2;
    font-weight: 800;
    margin-bottom: 0;
  }

  @media (min-width: 756px) {
    height: 350px;
  }

  @media (min-width: 1024px) {
    height: 300px;
    align-items: flex-start;
    padding: 2rem;

    p {
      font-size: 0.875rem !important;
      line-height: 1.3;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;

  h4 {
    font-size: 1.125rem;
    line-height: 1.2;
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
  }

  @media (min-width: 1024px) {
    h4 {
      font-size: 1.125rem;
      line-height: 1.2;
    }
  }
`;

const InterviewCard = ({ blok, index }) => {
  const desc = render(blok.description);

  return (
    <Container>
        <InfoContainer>
          <p className='card-index'>{index}</p>
          <h4>{blok.title}</h4>
        </InfoContainer>
        <p>{desc}</p>
    </Container>
  );
};

export default InterviewCard;
