import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  width: 100%;
  padding: 4.5rem 15px 0 15px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;

  div {
    max-width: 1040px;
    margin: auto;
    font-size: .9rem;
    line-height: 1.45;
    color: #222;

    a {
      text-decoration: none;
      color: #0f65ef;
      font-weight: 800;
    }
  }

  @media (min-width: 1040px) {
    padding: 4.5rem 0 0 0;

    p {
      width: 70%;
    }

    img {
      margin-top: -150px;
    }
  }
`;

const JobsDisclaimer = ({ blok }) => {
  const desc = render(blok.description);
  return (
    <Container>
      <div>{desc}</div>
      <img src={blok.image.filename} alt={blok.image.alt} />
    </Container>
  );
};

export default JobsDisclaimer;
