import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 3px;
  margin-top: 15px;
  padding: 1rem;
  height: fit-content;

  @media (min-width: 768px) {
    padding: 1.5rem;
    flex-direction: row;
  }
`;

const ContentContainer = styled.div`
  h2 {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.2;
  }

  p {
    margin-bottom: 1.5rem;
  }
`;

const ColorsContainer = styled.div`
  width: 263px;
  min-width: 263px;
`;

const Color = styled.div`
  height: 60px;
  width: 60px;
  background-color: ${props => props.color};
  border-radius: 50%;
  margin-right: 15px;
`;

const ColorCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
`;

const ColorInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 0.875rem;
    line-height: 1.3;
  }
`;

const DownloadButton = styled.a`
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  background-color: #0f65ef;
  color: #fff;
  border-color: #0f65ef;
  border: 1px solid transparent;
  transition: background-color .1s ease 0s;
  margin-bottom: 1rem;

  &:hover {
    background-color: #093c8f;
    transition: background-color .15s ease 0s;
  }
`;

const BrandAssets = ({ blok }) => {
  const desc = render(blok.content);

  const hexToRgb = hex => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `R:${r}  G:${g} B:${b}`;
  };

  return (
    <Container>
      <ContentContainer>
        {desc}
        <DownloadButton href={blok.button_link}>{blok.button_text}</DownloadButton>
      </ContentContainer>
      <ColorsContainer>
          <ColorCard>
            <Color color={blok.color_one} />
            <ColorInfo>
              <p>{blok.color_one}</p>
              <p>{hexToRgb(blok.color_one)}</p>
            </ColorInfo>
          </ColorCard>

          <ColorCard>
            <Color color={blok.color_two} />
            <ColorInfo>
              <p>{blok.color_one}</p>
              <p>{hexToRgb(blok.color_two)}</p>
            </ColorInfo>
          </ColorCard>

          <ColorCard>
            <Color color={blok.color_three} />
            <ColorInfo>
              <p>{blok.color_one}</p>
              <p>{hexToRgb(blok.color_three)}</p>
            </ColorInfo>
          </ColorCard>
      </ColorsContainer>
    </Container>
  );
};

export default BrandAssets;
