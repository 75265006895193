import '../styles/main.scss';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import i18n from '../i18n';
import ErrorPage from 'next/error';
import { useDocumentReferrerPolyfill } from '../client-event-tracking/hooks';
import { ErrorBoundary } from '@buzzfeed/react-components';
import * as Sentry from '@sentry/nextjs';
import Page from '../../src/components/Page';
import Table from '../../src/components/Table';
import Grid from '../../src/components/Grid';
import SiteHead from '../components/SiteHead';
import Content from '../components/Content';
import Iframe from '../components/Iframe';
import AnchorText from '../components/AnchorText';
import OneTrustLink from '../components/OneTrustLink';
import Divider from '../components/Divider';
import Grid2D from '../components/Grid2D';
import { storyblokAccessToken } from '../constants';
require.context('../public/static/locales', true, /\.json$/);
// local development - a11y logging
import '../utils/reactAxeSetup';
import Header from '../components/Header';
import { ThemeProvider } from 'styled-components';
import LeftTextRightImage from '../components/buzzfeed/about/LeftTextRightImage';
import AboutArticleSection from '../components/buzzfeed/about/AboutArticleSection';
import OurTeam from '../components/buzzfeed/about/OurTeam';
import BannerImageWIthText from '../components/buzzfeed/about/BannerImageWIthText';
import JobsHeader from '../components/buzzfeed/jobs/JobsHeader';
import JobsFirstBlok from '../components/buzzfeed/jobs/JobsFirstBlok';
import SEO from '../components/SEO';
import Erg from '../components/buzzfeed/jobs/Erg';
import ErgCard from '../components/buzzfeed/jobs/ErgCard';
import Image from '../components/Image';
import JobsImageBlock from '../components/buzzfeed/jobs/JobsImageBlock';
import JobsPerks from '../components/buzzfeed/jobs/JobsPerks';
import PerkCard from '../components/buzzfeed/jobs/PerkCard';
import JobsSecondImageBlock from '../components/buzzfeed/jobs/JobsSecondImageBlock';
import JobOpenings from '../components/buzzfeed/jobs/JobOpenings';
import JobsPeople from '../components/buzzfeed/jobs/JobsPeople';
import JobsInterview from '../components/buzzfeed/jobs/JobsInterview';
import WorkplaceAwards from '../components/buzzfeed/jobs/WorkplaceAwards';
import JobsDisclaimer from '../components/buzzfeed/jobs/JobsDisclaimer';
import PressBackground from '../components/buzzfeed/press/PressBackground';
import PressPage from '../components/buzzfeed/press/PressPage';
import ContactPage from '../components/buzzfeed/contact/ContactPage';
import LegalPage from '../components/buzzfeed/legal/LegalPage';
import AssetPage from '../components/buzzfeed/assets/AssetPage';
import BuzzfeedHeader from '../components/buzzfeed/BuzzfeedHeader';
import AccessibilityPage from '../components/buzzfeed/accessibility/AccessibilityPage';
import AppPromoPage from '../components/AppPromoPage';

const components = {
  content: Content,
  Page: Page,
  page: Page,
  table: Table,
  grid: Grid,
  grid_2d: Grid2D,
  iframe: Iframe,
  divider: Divider,
  header: Header,
  'anchor_text': AnchorText,
  'one_trust_link': OneTrustLink,
  'banner_image_with_text': BannerImageWIthText,
  'left_text_right_image': LeftTextRightImage,
  'about_article_section': AboutArticleSection,
  'our_team': OurTeam,
  'jobs_header': JobsHeader,
  'jobs_first_blok': JobsFirstBlok,
  'SEO': SEO,
  'jobs_erg': Erg,
  'erg_card': ErgCard,
  'image': Image,
  'jobs_image_block': JobsImageBlock,
  'jobs_perks': JobsPerks,
  'perk_card': PerkCard,
  'jobs_second_image_block': JobsSecondImageBlock,
  'job_openings': JobOpenings,
  'jobs_people': JobsPeople,
  'jobs_interview': JobsInterview,
  'workplace_awards': WorkplaceAwards,
  'jobs_disclaimer': JobsDisclaimer,
  'press_background': PressBackground,
  'press_page': PressPage,
  'contact_page': ContactPage,
  'legal_page': LegalPage,
  'asset_page': AssetPage,
  'buzzfeed_nav': BuzzfeedHeader,
  'accessibility_page': AccessibilityPage,
  'app_promo_page': AppPromoPage,
};

storyblokInit({
  accessToken: storyblokAccessToken,
  apiOptions: {
    region: 'us',
  },
  cache: {
    clear: 'auto',
    type: 'memory',
  },
  use: [apiPlugin],
  components,
});


const defaultTheme = {
  fontSizes: {
    xs: '12px',
    sm: '20px',
    md: '18px',
    lg: '30px',
    xl: '48px',
  }
}

function Application({ Component, pageProps }) {
  useDocumentReferrerPolyfill();

  return (
    <ThemeProvider theme={defaultTheme}>
      <ErrorBoundary fallbackRender={() => (
          <ErrorPage
            statusCode={500}
            title="An unexpected error has occurred"
          />
        )}
        onError={(e) => {Sentry.captureException(e)}}
      >
        <SiteHead {...pageProps} />
        <Component {...pageProps} />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default i18n.appWithTranslation(Application);
