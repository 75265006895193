import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h4+p>a {
    color: #0f65ef;
    &:hover {
      text-decoration: underline;
    };
  }
`;

const ContactRte = ({blok}) => {
  const content = render(blok.content);

  return (
    <Container>
      <div>{content}</div>
    </Container>
  );
};

export default ContactRte;
