import Head from 'next/head';

const Metadata = ({ metadata: m }) => {
  return (
    <Head>
      <meta key="copyright" name="copyright" content={m.copyright} />
      <meta
        key="apple-mobile-web-app-capable"
        name="apple-mobile-web-app-capable"
        content="yes"
      />
      {m.appTitle && (
        <meta
          key="apple-mobile-web-app-title"
          name="apple-mobile-web-app-title"
          content={m.appTitle}
        />
      )}
      <link
        key="shortcut-icon"
        rel="shortcut icon"
        crossOrigin=""
        href={m.iconSrc}
        type="image/x-icon"
      />
      {m.title && <meta key="title" name="title" content={m.title} />}
      {m.description && <meta key="description" name="description" content={m.description} />}
      <link key="canonical" rel="canonical" href={m.canonicalUrl} />
      {m.image && <link key="image_src" rel="image_src" href={m.image} />}

      {/* Facebook */}
      <meta
        key="fb:smart_publish:robots"
        property="fb:smart_publish:robots"
        content="noauto"
      />
      <meta key="fb:app_id" property="fb:app_id" content={m.facebookAppID} />

      {/* Open Graph */}
      <meta key="og:url" property="og:url" content={m.canonicalUrl} />
      <meta key="og:site_name" property="og:site_name" content={m.siteName} />

      {m.title && <meta key="og:title" property="og:title" content={m.title} />}
      {m.description && <meta
        key="og:description"
        property="og:description"
        content={m.description}
      />}

      {/* Twitter */}
      <meta
        key="twitter:site"
        name="twitter:site"
        content={`https://www.twitter.com/${m.twitterAccount}`}
      />
      <meta key="twitter:url" name="twitter:url" content={m.canonicalUrl} />
      {m.title && <meta key="twitter:title" name="twitter:title" content={m.title} />}
      {m.description && <meta
        key="twitter:description"
        name="twitter:description"
        content={m.description}
      />}
      <meta
        key="twitter:site:id"
        name="twitter:site:id"
        content={m.twitterSiteId}
      />
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
    </Head>
  );
};

export default Metadata;
