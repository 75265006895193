/* eslint-disable camelcase */
import {
  createClientEvent,
  GLOBAL_NAMESPACE as CET_NAMESPACE
} from '@buzzfeed/client-event-tracking';
import { pageview, external_link } from '@buzzfeed/client-event-tracking/events';
import { trackPerformance } from '@buzzfeed/performance';
import { CLUSTER } from '../constants';


const sendClientEvent = createClientEvent({
  env: CLUSTER,
  /**
   * @todo
   * The `source` value should match your project. If you are unsure what the value should be,
   * reach out in the #analytics-support channel.
   */
  source: 'web_bf',
});

/**
 * @todo
 * These fields are also required. If you are unsure what the value should be, reach out in the
 * #analytics-support channel.
 * Refer to bf_bpage_ui or tasty_ui for different patterns of defining these
 * values dynamically based on route and/or page props.
 * For single page apps, make sure to update the usePageviewTracking hook's dependencies
 * to indicate what should trigger additional pageviews
 */
export const commonDataLayer = pageData => () => ({
  context_page_type: pageData.type || '',
  context_page_id: 0,
  destination: '',
  page_edition: '',
  referrer_uri: window?.[CET_NAMESPACE]?.referrer || document.referrer,
});

const createClientEventWithCommonData = event_config => (pageData, ...layers) => {
  sendClientEvent(event_config, commonDataLayer(pageData), ...layers);
};

export const trackClientPageview = createClientEventWithCommonData(pageview);
export const trackClientExternalLink = createClientEventWithCommonData(external_link);

export const trackClientPerformance = (pageData, ...layers) => {
  trackPerformance(sendClientEvent, {
    layers: [commonDataLayer(pageData), ...layers],
  });
};
