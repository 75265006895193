import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    border-radius: 50%;
  }

  h4 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2;
    color: #222;
    margin-top: 10px;
    text-align: center;

    @media (min-width: 1024px) {
      font-size: 1.375rem;
    }
  }

  p {
    font-size: 0.75rem;
    line-height: 1.3;
    color: #222;
    text-align: center;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  animation: ${fadeIn} 0.2s ease-out;
  padding: 2rem;
  max-width: 700px;
  margin: 100px 15px 0 15px;
  position: relative;

  img {
    width: 96px;
    border-radius: 50%;
    top: -66px;
    position: relative;
  }

  h4 {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.2;
    color: #222;
    margin-top: -56px;
  }

  p {
    color: #222;
  }

  #title {
    font-size: 1.125rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }

  button {
    position: absolute;
    top: 25px;
    right: 25px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
`;

const TeamCard = ({ blok }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Container onClick={toggleModal}>
        <img src={blok.image.filename} alt={blok.alt} />
        <h4>{blok.name}</h4>
        <p>{blok.title}</p>
      </Container>
      {showModal && (
        <ModalOverlay onClick={toggleModal}>
          <ModalContent>
            <img src={blok.image.filename} alt={blok.alt} />
            <h4>{blok.name}</h4>
            <p id="title">{blok.title}</p>
            <p>{blok.bio}</p>
            <button onClick={toggleModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default TeamCard;
