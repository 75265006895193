import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import SEO from './SEO';

const Page = ({ blok, className, site }) => {
  return (
    <main className={className} {...storyblokEditable(blok)} key={blok._uid}>
      {blok.SEO && <SEO blok={blok.SEO} />}
      {blok.body.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} site={site} />
      ))}
    </main>
  );
}

export default Page;
