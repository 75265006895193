import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  width: 100%;
  padding-top: 4.5rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1060px;
  margin: auto;
  padding: 0 15px;

  h3 {
    font-size: 32px;
    line-height: 1;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    line-height: 1.45;
    font-size: 1rem;
    color: #222;
  }

  a {
    text-decoration: none;
    color: #0f65ef;
    font-weight: 800;
  }

  @media (min-width: 768px) {
    padding: 0;

    h3 {
      font-size: 56px;
    }

    p {
      margin-bottom: 2rem;
    }
  }
`;

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  img {
    width: calc(33.333% - 10px);
    margin-bottom: 10px;

    &:first-child,
    &:last-child {
      display: none;
    }
  }

  @media (min-width: 768px) {
    img {
      width: calc(25% - 10px);

      &:first-child {
        display: block;
      }
    }
  }

  @media (min-width: 1024px) {
    img {
      width: calc(20% - 10px);

      &:last-child {
        display: block;
      }
    }
  }
`;

const JobsPeople = ({ blok }) => {
  const desc = render(blok.description);

  return (
    <Container>
      <HeaderContainer>
        <h3>{blok.title}</h3>
        <div>{desc}</div>
      </HeaderContainer>
      <ImagesContainer>
        {blok.images.map((image, index) => (
          <img src={image.filename} alt={image.alt} key={index} />
        ))}
      </ImagesContainer>
    </Container>
  );
};

export default JobsPeople;
