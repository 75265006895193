import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 3px;
  margin-top: 15px;
  padding: 1rem;
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
  height: fit-content;

  h2 {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  h6 {
    font-size: 0.75rem;
    line-height: 1.3;
    color: #666;
    margin-bottom: 1.5rem;
    font-weight: 400;

    b {
      font-weight: 600;
    }
  }

  h4 {
    font-size: 0.875rem;
    line-height: 1.3;
    font-weight: normal;

    b {
      font-weight: 600;
    }

    a {
      text-decoration: none;
      color: #0f65ef;
    }
  }

  p {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #222;

    a {
      text-decoration: none;
      color: #0f65ef;
    }
  }

  @media (min-width: 768px) {
    padding: 1.5rem;
  }
`;

const Communications = ({ blok }) => {
  const desc = render(blok.content);

  return (
    <Container>
      {desc}
    </Container>
  );
};

export default Communications;
