import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer';

const Content = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div>
        {render(blok?.content, {
          defaultBlokResolver: (name, props) => {
            const blok = { ...props, component: name };
            return <StoryblokComponent blok={blok} key={props._uid} />;
          },
        })}
      </div>
    </div>
  );
};

export default Content;
