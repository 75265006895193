import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';
import InterviewCard from './InterviewCard';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ArrowLeft from '../../icons/ArrowLeft';
import ArrowRight from '../../icons/ArrowRight';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 4.5rem 15px 8.5rem 15px;

  h3 {
    font-size: 1.375rem;
    line-height: 1.2;
    margin-bottom: 1.5rem
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.45;
    font-size: 1rem;
    color: #222;
  }

  .react-multi-carousel-track {
    padding: 20px 0 60px 0;
  }

  .react-multi-carousel-dot-list {
    li button {
      color: #0f65ef;
      background-color: rgba(15, 101, 239, 0.4);;
      border: none;
    }
  }

  .react-multiple-carousel__arrow {
    background: none;

    &:before {
      color: #0f65ef;
      font-size: 40px;
    }
  }

  .react-multiple-carousel__arrow--right {
    right: -10px !important;
  }

  .react-multiple-carousel__arrow--left {
    left: -10px !important;
  }

  .react-multi-carousel-dot--active button {
    background-color: #0f65ef !important;
  }

  svg {
    width: 50px;
    height: 50px;
    background: none;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    h3 {
      font-size: 56px;
      line-height: 1.2;
      margin-bottom: 1.5rem;
    }

    p {
      margin-bottom: 2rem;
      font-size: 1.125rem;
    }
  }
`;

const CardsContainer = styled.div`
  max-width: 100%;
  width: 100%;

  li {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  @media (min-width: 768) {
    width: 80%;
  }

  @media (min-width: 1024px) {
    max-width: 1060px;
    margin: auto;
  }
`;

const LeftArrow = styled.button`
  position: absolute;
  left: 0;
`;

const RightArrow = styled.button`
  position: absolute;
  right: 0;
`;

const CustomLeftArrow = ({ onClick }) => {
  return (
    <LeftArrow onClick={onClick}>
      <ArrowLeft />
    </LeftArrow>
  )
}

const CustomRightArrow = ({ onClick }) => {
  return (
    <RightArrow onClick={onClick}>
      <ArrowRight />
    </RightArrow>
  )
}

const JobsInterview = ({ blok }) => {
  const desc = render(blok.description);

  return (
    <Container>
      <CardsContainer>
        <h3>{blok.title}</h3>
        <div>{desc}</div>
        <Carousel
          responsive={responsive}
          showDots={true}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {blok.process_cards.map((card, index) => (
            <InterviewCard blok={card} index={index + 1} key={card._uid} />
          ))}
        </Carousel>
      </CardsContainer>
    </Container>
  );
};

export default JobsInterview;
