import { useEffect } from 'react';
import { trackClientPageview } from '../';

/**
 * For sending pageview CET events
 * @param {Object} pageData - Data about the page/content to include in the event
 * @param {...object} layers - Optional, additional fields to include in the event
 */
export function usePageviewTracking(pageData, ...layers) {
  useEffect(() => {
    trackClientPageview(pageData, ...layers);
    // @todo: For a single page app, update dependencies so that the pageview gets
    //  triggered when data changes.  Unless memoized do not use the pageData object
    //  directly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
