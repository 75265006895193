import styled from 'styled-components';

const Container = styled.div`
  width: 300px;
  padding-top: 4.5rem;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 360px;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 3px;
  padding: 1rem;
  align-items: center;
  height: 500px;

  img {
    width: 100px;
    height: 100px;
    margin-bottom: 1rem;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    width: 520px;
    height: 300px;
    max-width: 520px;
    align-items: flex-start;
    padding: 2rem;

    img {
      margin-right: 20px;
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  .erg-title {
    font-size: 1.125rem;
    line-height: 1.2;
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
  }

  .erg-description {
    font-size: 0.875rem;
    line-height: 1.3;
  }

  @media (min-width: 1024px) {
    flex-direction: column;

    .erg-title {
      font-size: 1.125rem;
      line-height: 1.2;
    }
  
    .erg-description {
      font-size: 0.875rem;
      line-height: 1.3;
    }
  }
`;

const ErgCard = ({ blok }) => {
  return (
    <Container>
      <img src={blok.image.filename} alt={blok.image.alt} />
      <InfoContainer>
        <h4 className='erg-title'>{blok.title}</h4>
        <p className='erg-description'>{blok.description}</p>
      </InfoContainer>
    </Container>
  );
};

export default ErgCard;
