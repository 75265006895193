/* eslint-disable react/no-unknown-property */
/* eslint-disable @next/next/no-sync-scripts */
import Head from 'next/head';
import { jsProfilerAsString } from '@buzzfeed/performance';

const InlineScripts = ({ site }) => {
  return (
    <>
      <Head>
        {/* Inline JS */}
        <script
          key="js-profiling"
          dangerouslySetInnerHTML={{ __html: jsProfilerAsString() }}
        />

        {site === 'buzzfeed' && (
          <>
            <link rel="stylesheet" href="https://use.typekit.net/amc2eom.css" />
            <link rel="preload" href="https://use.typekit.net/af/034166/00000000000000003b9b2056/27/l?primer=b3195baa0f8561ca2f0a3f81377b0c49080c234114cdfff11a59797d1967a943&amp;fvd=n8&amp;v=3" as="font" type="font/woff2" crossorigin="" />
            <link rel="preload" href="https://use.typekit.net/amc2eom.css" as="style" />
          </>
        )}
      </Head>
    </>
  );
};

export default InlineScripts;
