import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { apiPrefix } from '../../../constants';
import { useRouter } from 'next/router';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 3px;
  margin-top: 15px;
  padding: 1rem;
  height: fit-content;

  @media (min-width: 768px) {
    padding: 1.5rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 15px;

  h2 {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.2;
  }

  a {
    text-decoration: none;
    color: #0f65ef;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;

    &:hover {
      color: #093c8f;
      transition: color .15s ease 0s;
    }
  }
`;

const PressItem = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #000;
  width: 100%;
  margin-bottom: 25px;

  img {
    width: 100%;
    margin-bottom: 10px;
  }

  h3 {
    color: #222;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.3;

    &:hover {
      color: #0f65ef;
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 15px;
    flex-direction: row;

    img {
      max-width: 250px;
      margin-right: 15px;
    }

    h3 {
      font-size: 1.125rem;
      line-height: 1.2;
    }
  }
`;

const InThePress = ({ blok }) => {
  const [pressItems, setPressItems] = useState([]);
  const router = useRouter();

  useEffect(() => {
    const fetchPressItems = async () => {
      let url = `${apiPrefix}press`;
      if (router.query.edition === 'ja') {
        url += '?country=jp&lang=ja';
      }

      const res = await fetch(url);
      const data = await res.json();
      // only return the first 4 items from data.results if there are results
      if (data.results.length > 0) {
        data.results = data.results.slice(0, 5);
      }
      setPressItems(data.results);
    };
    fetchPressItems();
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <h2>{blok.title}</h2>
        <a href={blok.link_url} target="_blank" rel="noopener noreferrer">{blok.button_text}</a>
      </HeaderContainer>
      {pressItems.map((item, index) => (
        <PressItem key={index} href={item.source_uri}>
          <img src={item.thumbnails[0].sizes[3].url} alt={item.thumbnails[0].sizes[3].alt_text} />
          <h3>{item.name}</h3>
        </PressItem>
      ))}
    </Container>
  );
};

export default InThePress;
