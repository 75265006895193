import styled from 'styled-components';
import SplashArticle from './SplashArticle';
import ArticleCard from './ArticleCard';

const Container = styled.div`
  background-color: #f4f4f4;
  padding-bottom: 50px;
`;

const SplashContainer = styled.div`
  padding: 40px 0 0 0 ;

  @media (max-width: 1024px) {
    padding: 40px 15px 0;
  }
`;

const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 990px;
  padding: 0;
  margin: 20px auto 0;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 15px;
  }
`;

const AboutArticleSection = ({ blok }) => {
  return (
    <Container>
      <SplashContainer>
        <SplashArticle blok={blok.splash[0]} />
      </SplashContainer>
      <ArticleGrid>
        {blok.article_grid.map((article, index) => {
          return <ArticleCard key={index} blok={article} />;
        })}
      </ArticleGrid>
    </Container>

  );
};

export default AboutArticleSection;
