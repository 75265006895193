import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';
import { useRouter } from 'next/router';
import Table from '../../Table';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  height: 100%;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PageContainer = styled.div`
  width: 100%;
  max-width: 1060px;
  margin: 0 auto;
  padding: 2rem 15px 0 15px;

  h1 {
    background: #ffee00;
    color: #000;
    padding: 0.5rem; 1rem;
    width: fit-content;
    font-size: 0.875rem;
    line-height: 1.3;
    margin-top: 1rem;
  }

  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const LegalLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const LegalLink = styled.a`
  background-color: ${props => props.isActive ? '#0f65ef' : '#fff'};
  color: ${props => props.isActive ? '#fff' : '#0f65ef'};
  padding: 0.5rem 0.75rem;
  border-radius: 3px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.3;
  margin-right: 0.5rem;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  
  @media (min-width: 768px) {
    padding: 0.5rem 1.5rem;
  }
`;

const ContentContainer = styled.div`
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 2rem;
  margin-top: 1rem;
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;

  h2, h3 {
    color: #0f65ef;
    font-size: inherit;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  b {
    font-weight: 600;
  }

  p {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;
    color: #0f65ef;
  }

  ul {
    padding-bottom: 1rem;
    padding-left: 2rem;
  }

  th {
    text-align: left;
  }

  td {
    padding: 10px;
  }

  table {
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`;

const TranslationContainer = styled.div`
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 2rem;
  margin-top: 1rem;
  height: fit-content;
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;

  h2, h3 {
    font-size: 1.125rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  b {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: #0f65ef;
    font-weight: 600;
  }

  ul {
    padding-bottom: 1rem;
    padding-left: 2rem;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const LegalPage = ({ blok }) => {
  const translationContent = render(blok.translations);
  const router = useRouter();

  const isLinkActive = (path) => {
    return router.query.slug.includes(path.substring(1));
  }

  return (
    <Container>
      <PageContainer>
        <h1>{blok.title}</h1>
        <LegalLinkContainer>
          {blok.nav_links.map((link, index) => {
            if (link.title) {
              return (
                <LegalLink href={link.link_url} key={index} isActive={isLinkActive(link.link_url)}>
                  {link.title}
                </LegalLink> 
              );
            }
            return null;
          })}
        </LegalLinkContainer>

        <BodyContainer>
          <SectionContainer>
            {blok.sections.map((section, index) => { 

              const content = render(section.content, {
                blokResolvers: {
                    ['table']: (props) => <Table blok={props} />
                }
              });
              return (
                <ContentContainer key={index}>
                  {content}
                </ContentContainer>
              );
            })}
          </SectionContainer>
          <TranslationContainer>
            {translationContent}
          </TranslationContainer>
        </BodyContainer>
      </PageContainer>
    </Container>
  );
};


export default LegalPage;
