const Table = ({ blok }) => {
  const table = blok.table;
  const tableHeadData = table?.thead;
  const tableBodyData = table?.tbody;

  return (
    <table>
      <thead>
        <tr>
          {tableHeadData?.map((data) => {
            return <th key={data._uid}>{ data.value }</th>
          })}
        </tr>
      </thead>
      <tbody>
        {tableBodyData?.map((data) => {
          return (
            <tr key={data._uid}>
              {data.body?.map((bodyData) => {
                return <td key={bodyData._uid}>{ bodyData.value }</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  );
};

export default Table;
