import styled from 'styled-components';

const Container = styled.a`
  width: 100%;
  background: #fff;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 100%;
  }
`;

const InfoContainer = styled.div`
  padding: 8px;

  h4 {
    color: #222;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.3;

    &:hover {
      color: #0f65ef;
    }
  }

  p {
    margin-top: 10px;
    color: #0f65ef;
    font-size: 0.75rem;
    line-height: 1.3;
    font-weight: 600;
  }

  @media (min-width: 1024px) {
    padding: 15px;
  }
`;

const ArticleCard = ({ blok }) => {
  return (
    <Container href={blok.link_url} target="_blank">
      <img src={blok.thumbnail.filename} alt={blok.thumbnail.alt} />
      <InfoContainer>
        <h4>{blok.headline}</h4>
        <p>{blok.source}</p>
      </InfoContainer>
    </Container>
  );
};

export default ArticleCard;
