/* eslint-disable @next/next/no-sync-scripts */
import Head from 'next/head';

import PreconnectDnsPrefetch from './PreconnectDnsPrefetch';
import InlineScripts from './InlineScripts';
import Metadata from './Metadata';
import { SiteMetaData, StaticMetaData } from '../../constants/metadata';

/**
 * When adding a new third-party scipt, consider using some of the various techniques
 * for lowering its network/execution priority when appropriate
 * so as to minimize the negative effect on performance
 * @see https://addyosmani.com/blog/script-priorities/
 */
const SiteHead = ({ type, site, ...props }) => {
  const pageName = props?.story?.slug;
  const pageMetaData = StaticMetaData[site][pageName];
  const siteMetaData = SiteMetaData[site];

  const metadata = {
    // general
    appTitle: siteMetaData?.siteName,
    copyright: siteMetaData?.copyright,
    canonicalUrl: `https://www.${site}.com/${pageName}`,
    description: pageMetaData?.description,
    iconSrc: '/static/images/favicon.ico',
    title: pageMetaData?.title,
    // Facebook
    facebookAppID: siteMetaData?.facebookAppID,
    // Open Graph
    siteName: siteMetaData?.siteName,
    // Twitter
    twitterAccount: siteMetaData?.social?.twitter,
    twitterSiteId: siteMetaData?.twitterSiteId,
  };

  /**
   * If possible, this is the preferred order based on research by Harry Roberts (csswizardry.com).
   *   1) <meta charset />
   *   2) <meta name="viewport" />
   *   3) <base> - Probably not needed but would place between here
   *   4) <title>
   *     - Placing close to the top helps display page title on browser tab sooner. If placed after
   *       blocking scripts, a slow  connection/computer might not see a title on the tab until
   *       scripts finish blocking.
   *   5) preconnect / dns-prefetch fallback
   *   6) <script></script> - Inline JS
   *   7) <script src=""></script>
   *   8) <link rel="stylesheet" />
   *   9) <style> - inline css </style>
   *  10) preload - place here due to bug in chrome
   *  11) <script src="" (async|defer)></script>
   *  12) prefetch / prerender - Files that might be needed later. Deprioritized.
   *  13) Everything else (icons, Open Graph, etc.)
   */
  return (
    <>
      {/* charset / viewport / base / title */}
      <Head>
        <meta key="charset" charSet="utf-8" />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <title>{`title_${type}_page`}</title>
      </Head>

      {/**
       * preconnect -  for the most important/critical assets
       * dns-prefetch - as a fallback for preconnect and for all other third party origins
       */}
      <PreconnectDnsPrefetch />

      {/* Inline JS */}
      <InlineScripts site={site} />

      {/* External stylesheets go here (`<link rel="stylesheet" />`). */}
      <link rel="stylesheet" href="https://use.typekit.net/amc2eom.css" />

      {/* preload links go here (`<Head><link rel="preload" ... /></Head>`) */}
      <link
        rel="preload"
        href="https://use.typekit.net/af/034166/00000000000000003b9b2056/27/l?primer=b3195baa0f8561ca2f0a3f81377b0c49080c234114cdfff11a59797d1967a943&fvd=n8&v=3"
        as="font"
        type="font/woff2"
        crossOrigin=""
      />
      <link
        rel="preload"
        href="https://use.typekit.net/amc2eom.css"
        as="style"
      />

      {/* Async or deferred scripts go here (`<script src="" async|defer></script>`). */}

      {/* Everything else ((icons, Open Graph, etc.) */}
      <Metadata metadata={metadata} />
    </>
  );
};

export default SiteHead;
