import React, { useEffect, useState } from 'react';
import styled, { keyframes, css }  from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
`;

const reverseSpinAnimation = keyframes`
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
`;


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-bottom: 25px;
  padding: 2.5rem 15px;
  max-width: 1060px;
  margin: auto;

  h3 {
    font-size: 32px;
    line-height: 1;
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    padding: 4.5rem 15px;

    h3 {
      font-size: 56px;
    }
  }
`;

const StyledAccordion = styled(Accordion)`

`;

const StyledAccordionItemHeading = styled(AccordionItemHeading)`
  height: 50px;
  margin: 15px 0;
`;

const StyledAccordionItemButton = styled(AccordionItemButton)`
  border-bottom: 3px solid #0f65ef;
  cursor: pointer;
  font-size: 1.375rem;
  line-height: 1.2;
  font-weight: 600;
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;
`;
const CaretContainer = styled.div`
  display: inline-flex;
  padding-left: 10px;
  padding-right: 10px;
  img {
    vertical-align: middle;
  }
`;

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  padding: 25px 0 0 0;
`;

const JobContainer = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  h4 {
    margin-bottom: 1rem;
    font-size: 1.375rem;
    line-height: 1.2;
    font-weight: 600;
  }

  h5 {
    color: #222;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.2;

    &:hover {
      color: #0f65ef !important;
    }
  }

  a {
    text-decoration: none;
  }

  div {
    margin-bottom: 1rem;

    @media (min-width: 768px) {
      padding-left: 20px;
    }
  }

  p {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.2;
    color: #757575;
  }
`;


const StyledImage = styled(CaretContainer)`
  animation: ${props => props.isFlipped ? css`${spinAnimation} .3s linear` : css`${reverseSpinAnimation} .3s linear`};
  transform: ${props => (props.isFlipped ? 'rotate(180deg)' : 'none')};
`;


const JobOpenings = ({ blok }) => {
  const [departments, setDepartments] = useState([]);
  const [isFlippedArray, setIsFlippedArray] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleAccordionItemClick = (index) => {
    setIsFlippedArray(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const filterDepartments = (data) => {
    const filteredDepartments = (data?.departments || []).reduce((accumulator, department) => {
      const filteredChildren = department.children.filter(child => child.jobs.length > 0);
      if (filteredChildren.length > 0) {
        accumulator.push({
          ...department,
          children: filteredChildren
        });
      }
      return accumulator;
    }, []);

    return { departments: filteredDepartments };
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://boards-api.greenhouse.io/v1/boards/buzzfeed/departments?render_as=tree');
        const data = await response.json();
        const filteredDepartments = filterDepartments(data);

        setIsFlippedArray(new Array(filteredDepartments?.departments?.length).fill(true));

        setDepartments(filteredDepartments);
      }
      catch (error) {
        console.error('Error fetching departments', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const preExpanded = departments?.departments?.map(department => department.id.toString());

  if (loading) {
    return null;
  }

  return (
    <Container>
      <h3 id="job-listings">{blok.title}</h3>
      <StyledAccordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        preExpanded={preExpanded}
      >
        {departments?.departments?.map((department, index) => (
          <AccordionItem key={department.id} uuid={department.id.toString()}>
            <StyledAccordionItemHeading onClick={() => handleAccordionItemClick(index)}>
                <StyledAccordionItemButton>
                  {department.name}
                  <StyledImage isFlipped={isFlippedArray[index]}>
                    <img
                      src={blok.caret_image.filename}
                      alt={`Caret ${isFlippedArray[index] ? 'up' : 'down' }`}
                      width={20}
                      height={20}
                    />
                  </StyledImage>
                </StyledAccordionItemButton>
            </StyledAccordionItemHeading>
            <StyledAccordionItemPanel>
            {department?.children?.map(subdepartment => (
              <JobContainer key={subdepartment.id}>
                <h4>{subdepartment.name}</h4>
                {subdepartment?.jobs?.map(job => (
                  <div key={job.id}>
                    <a href={job.absolute_url}>
                      <h5>{job.title}</h5>
                      <p>{job.location.name}</p>
                    </a>
                  </div>
                ))}
              </JobContainer>
            ))}
            </StyledAccordionItemPanel>
        </AccordionItem>
        ))}
      </StyledAccordion>
    </Container>
  );
};

export default JobOpenings;
