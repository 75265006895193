import styled from 'styled-components';

const Container = styled.div`
  max-width: 1080px;
  margin: auto;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    width: 100%;

    @media (min-width: 768px) {
      max-width: 60%;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 4.5rem 0;
  }
`;

const TextContainer = styled.div`
  padding-left: 20px;
  min-width: 300px;
  padding-top: 20px;
  padding-bottom: 70px;

  h1 {
    font-size: 32px;
  }

  p {
    line-height: 1.45;
    color: #222;
    font-size: 1.125rem;
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    padding-top: 20px;
    padding-bottom: 0;

    h1 {
      font-size: 56px;
    }
  }

  @media (min-width: 1024px) {
    padding-top: 90px;
  }
`;

const OpeningsLink = styled.a`
  background-color: #0f65ef;
  color: #fff;
  border-color: #0f65ef;
  padding: 10px 30px;
  font-weight: 600;
  border-radius: 3px;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #093c8f;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  max-width: 200px !important;
  width: 200px;
  height: auto;
  margin-left: -200px;
  margin-top: 150px;
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const JobsHeader = ({ blok }) => {
  return (
    <Container>
      <StyledImage src={blok.secondary_image.filename} alt={blok.secondary_image.alt} />
      <img src={blok.image.filename} alt={blok.image.alt} />
      <TextContainer>
        <h1>{blok.title}</h1>
        <p>{blok.description}</p>
        <OpeningsLink href="#job-listings">See Openings</OpeningsLink>
      </TextContainer>
    </Container>
  );
};

export default JobsHeader;
