import Head from 'next/head';

/**
 * preconnect:
 *   Only preconnect to critical domains you will use soon because the browser closes any
 *   connection that isn't used within 10 seconds. Unnecessary preconnecting can delay other
 *   important resources, so limit the number of preconnected domains and test the impact
 *   preconnecting may have on metrics such as Largest Contentful Paint and Time To Interative.
 * dns-prefetch:
 *   If a page needs to make connections to many third-party domains, preconnecting all of them is
 *   counterproductive as explained above. For all the rest, use <link rel=dns-prefetch> to save
 *   time on the first step, the DNS lookup, which usually takes around 20–120 ms. This resource
 *   hint can also serve as a fallback. Support for dns-prefetch is better than support for
 *   preconnect. Browsers that don’t support preconnect will still get some added benefit by
 *   falling back to dns-prefetch.
 */
const PreconnectDnsPrefetch = () => {
  return (
    <>
      <Head>
        {/* image CDN */}
        <link rel="dns-prefetch" href="https://use.typekit.net" />
        <link rel="dns-prefetch" href="https://p.typekit.net" />

        {/* Sentry */}
        <link rel="dns-prefetch" href="https://sentry.io" />
      </Head>
    </>
  );
};

export default PreconnectDnsPrefetch;
