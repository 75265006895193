import styled from 'styled-components';

const Container = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    max-width: 990px;
    margin: auto;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;

  p {
    position: absolute;
    background: #ffee00;
    margin: 20px 0 0 20px;
    padding: 10px;
    font-size: 0.875rem;
    font-weight: 700;
    color: #000;
  }

  img {
    height: auto;
    width: 100px;
    display: flex;
    flex-grow: 1;

    @media (min-width: 1024px) {
      max-width: 590px;
    }
  }

  @media (min-width: 1024px) {
    width: 100px;
  }
`;

const ArticleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 15px;
  width: 100%;
  flex-grow: 1;

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #222;
    line-height: 1.3;

    &:hover {
      color: #0f65ef;
    }
  }

  #image-credit {
    color: #757575;
    font-size: 0.75rem;
    margin-top: 10px;
  }

  #source {
    font-weight: 600;
    font-size: 0.75rem;
    color: #0f65ef;
    margin-top: 10px;
  }

  @media (min-width: 1024px) {
    max-width: 400px;
    padding: 30px;

    h2 {
      font-size: 1.75rem;
    }
  }
`;

const SplashArticle = ({ blok }) => {
  return (
    <Container href={blok.link_url} target="_blank">
      <ImageContainer>
        <p>IN THE NEWS</p>
        <img src={blok.thumbnail.filename} alt={blok.thumbnail.alt} />
      </ImageContainer>
      <ArticleInfoContainer>
        <h2>{blok.headline}</h2>
        <p id="image-credit">Image Credit: {blok.image_credit}</p>
        <p id="source">{blok.source}</p>
      </ArticleInfoContainer>
    </Container>
  );
};

export default SplashArticle;
