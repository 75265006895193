import styled from 'styled-components';
import TeamCard from './TeamCard';
import { render } from 'storyblok-rich-text-react-renderer';

const Container = styled.div`
  background-color: #fff;
  max-width: 990px;
  margin: auto;
  padding: 25px 15px 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.2;
    margin-bottom: 15px;
    align-self: flex-start;
  }

  @media (min-width: 1024px) {
    padding: 50px 15px 0 15px;

    h3 {
      margin-bottom: 25px;
    }
  }
`;

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  max-width: 990px;
  padding: 0;
  margin: 20px auto 0;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 15px;
  }
`;

const JoinButton = styled.a`
  background-color: #0f65ef;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  padding: 10px 25px;
  text-decoration: none;
  margin-top: 50px;

  &:hover {
    background-color: #093c8f;
    transition: background-color .15s ease 0s;
  }

  @media (min-width: 1024px) {
    margin-top: 100px;
  }
`;

const DisclaimerText = styled.div`
  max-width: 660px;
  width: 100%;
  margin-top: 30px;
  text-align: center;
  font-style: italic;
  font-size: 0.75rem;
  line-height: 1.3;
  color: #222;
  margin-bottom: 40px;

  p {
    margin-bottom: 20px;
  }
`;

const OurTeam = ({ blok }) => {
  return (
    <Container>
      <h3>Our Team</h3>
      <TeamGrid>
        {blok.team_members.map((member, index) => {
          return <TeamCard key={index} blok={member} />;
        })}
      </TeamGrid>
      <JoinButton href="/about/jobs?country=en-us">Join the team</JoinButton>
      <DisclaimerText>
        {render(blok.disclaimer_text)}
      </DisclaimerText>
    </Container>
  );
};

export default OurTeam;
