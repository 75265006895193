import getConfig from 'next/config';

export const {
  CLUSTER,
  ENV,
  api_prefix: apiPrefix,
  api_gateway_url: apiGatewayUrl,
  base_url: baseUrl,
  facebook_app_id: facebookAppID,
  image_service_url: imageServiceUrl,
  recaptcha_site_key,
  sentry_dsn: sentryDsn,
  site_name: siteName,
  twitter_site_id: twitterSiteId,
  social,
  storyblok_access_token: storyblokAccessToken,
} = getConfig().publicRuntimeConfig;
