import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  background: linear-gradient(to bottom, #f4f4f4 50%, #fff 50%);
  justify-content: space-between;

  img {
    width: 49%;
  }

  img:first-child {
    width: 100%;

    @media (min-width: 768px) {
      width: 49%;
    }
  }

  img:last-child {
    position: relative;
    top: -80px;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  @media (min-width: 768px) {
    padding: 5rem 0;
  }
`;

const JobsImageBlock = ({ blok }) => {
  return (
    <Container>
      <img src={blok.first_image.filename} alt={blok.first_image.alt} />
      <img src={blok.second_image.filename} alt={blok.second_image.alt} />
    </Container>
  );
};

export default JobsImageBlock;
